<template>
  <el-container class="el-container">
    <div class="faq">
      <div class="text">
        <div class="title">常见问题</div>
        <!--<div class="tel">联系电话：400-0000-0000<br />客服服务时间：9:00-23:00</div>-->
      </div>
    </div>
    <div class="content">
      <el-row class="el-row" v-for="item in faqData" :key="item.title">
        <el-col class="icon">
          <img :alt="item.title" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAeCAYAAAA2Lt7lAAAEdElEQVRIS6WWbWxTZRTH/+fernsD2WQRFAwQNc6V3etkxowEQm/b221uTBOcRpFo+AB+ETSRmJDojIaoRBOMMWpiBFky6Ad8aSjr+4w6jKmT3rJoYljQMVgEs6kZ27r1OeYWurTSdhs+X+//nN95znnOOZewwOPxsFxd/eOSsbFksrNz4+QCzUDFhKdCRpMk8dMAaQTcBcBi6hl8mUCnJZJOyKg8brevmyrkJy/AHzVqifE+AEfGMO2U6QoTlxNodQYG0CWREi83u9TP8kFuAPhD8W0k0WEAlQCGGHwoRTjRalcvZBx4vbGKsspSu2Cxm4geBkBgdI//Ke/s7LQls0E5gOvOjwFEgHhz1QrLazZbrsF/o+wNGU5JwhEAd4DwuWtL/TYiEhndHOB6WmIAlYN5p+5QDkej8dVJgT0kkZsYfS5NeT5fGk6GB9ZYSO4j0FoAr+ia8voNgEDECF3LOR/QNXV/IGK0EnCMgaVpMXOP7lCfLFTMYNCoZxk/mOmaZbK1OurPmdr0DUJ9RpMQ6DdzvmqFfN/ISHINy9IZIjIL+uEM452MQbFX5w8n3iDi/QR87NKUXXMAfyT+AYGeY/Aet6a+F4gan4LxDIMPujV130LfvNcbq7FWWEcATJ1flqzZ1dg4k76BP2L8QsC9s8R3tmxRRoKRxGUQliZLsLJtkzK2UICpC0aNIDOcRNzksqvfk9mhVTWJKQaPuTX1NrOwM0zDBAy4NGXDYpynAZHE2wx+CYxnzYdCwWBsGcvWcTB+1h1K3anoT2tltpxjcI9bU7YvFhCInN0HiLdAtFe31x8in89Xailbbbb6eV1T1pkOfaGBupmJiuGOjtp/Fgvwh+NdRPQqhNitO+//KF2DQMQYBbhmemLmlvb2xquLdZqt94fjPUT0hERyi9Nu680U+QsCOph5q9uhem8W4PF45Kqa2osALy+hmZV2e+OVNCAYObuDIY4AdFLX6ttuFhAIG4+B4GHmqNuhanN94PP9Wmopmxwy5wkL0t3O+uBiIZ7+/vKqqSUGgLtT4I4WTf1qDpDu5qjxlGB0MzAqpaSHXK71vy8UwswUiCa6CTBHSUTXlLkxnzNNA2HjKAjbAf5NQO5o1tbH54N4Y7EK618ln5iFNYNLET+YPdpzAB7PoLV6eeo4Ex4BMMmccrodDeaMynt6ewdvlayp7wDUms4Zs83N2gM5Qd2wcLq6WNq4OXEQwItgPqo71B2FAKFook0wewE6M0uiPTvyjE3eldnbG7tdslovEjAqCMeKpGkDMTZB8F7dqR7Kpyu49ANhwwdCy3w1YGDMSqzYs1Zqtk1BQCwWKxn7u1QXQlxbOHkOEaVEieXb5s11lwpqikXoDye6iPjxQhpm/HFVSnU8am8YvylAIBL/EqCtBYNgTJRIXFsoPTmNls+J2UB+/+lqWCptLAs5W8PCMozpsgutrfdMF8tC0T8709AfMV4g4N08ToZ0TTH/9oqeeQHBrwcbOJXqIsCacwPwN7qmHvjfgPkczPf9XyeNyy7hai9qAAAAAElFTkSuQmCC">
        </el-col>
        <el-col>
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </el-col>
      </el-row>
    </div>
    <div class="copyright">Copyright © 2018-2023 福建省福栗网络科技有限责任公司版权所有 All Rights Reserved.   <a class="btn" target="_blank" href="https://beian.miit.gov.cn/">闽ICP备2021003651号-1</a></div>
  </el-container>
</template>

<script>
export default {
  name: "faq",
  data: function () {
    return {
      faqData: [
        {
          title: '商户怎么入驻？',
          text: '登录影栗官方网站（www.yinglib.com），在首页点击【免费入驻】，在打开的新页面填写相应的入驻资料后即可入驻。'
        },
        {
          title: '商户入驻需要收费吗？',
          text: '目前，商户入驻是完全免费的。'
        },
        {
          title: '入驻后怎么登录商户后台？',
          text: '登录方式：在影栗官方网站（www.yinglib.com）右上角，点击【商户登录】，在打开的页面输入账号和密码即可登录。'
        },
        {
          title: '商品后台的账号密码忘记了怎么办？',
          text: '商户在入驻时所填写的账号是管理员账号，管理员如果忘记了账号密码，可联系影栗进行密码重置，重置后的密码为“888888”。<br />商户管理员，可以登录商户后台，为自己的员工操作账号，员工如果忘记了账号密码，管理员可登录商户后台进行密码重置，重置后的密码为“888888”。'
        },
        {
          title: '商户有效期多久？',
          text: '目前，商户的有效期是永久有效。'
        },
        {
          title: '商品交易需要手续费吗？',
          text: '目前，商品交易手续费时按每笔订单的2%进行收取。'
        },
        {
          title: '商品交易手续费怎么收？',
          text: '订单交易完成时，系统会自动从销售金额中扣除相应金额的手续费。'
        },
        {
          title: '商品交易款怎么结算？',
          text: '商品交易款由票友友代收，产生的订单在24小时后，由商户发起提现申请。'
        },
        {
          title: '提现有门槛吗？',
          text: '单笔提现金额不可低于50元，每月提现次数为30次。'
        },
        {
          title: '提现账户有限制吗？',
          text: '目前，系统支持提现到支付宝账户和自动结算到银行卡。'
        }
      ]
    }
  }
}
</script>
<style>
body {
  background-color: #FFFFFF;
}
</style>
<style scoped>
.el-container {
  flex-direction: column;
}
.faq {
  width: 100%;
  height: 230px;
  background: #1E8CFC;
}
.faq .text {
  width: 1298px;
  height: 230px;
  margin: 0 auto;
  text-align: left;
  background-image: url("~@/static/faq.png");
  background-repeat: no-repeat;
  background-position: right bottom;
}
.faq .text .title {
  font-size: 42px;
  font-family: Microsoft YaHei,serif;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: 55px;
}
.faq .text .tel {
  font-size: 16px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
  margin-top: 5px;
}
.content {
  width: 1298px;
  margin: 0 auto;
  text-align: left;
  padding-top: 22px;
}
.content .el-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.content .el-row .icon {
  width: 50px;
  flex-grow: 1;
}
.content .el-row .title {
  font-size: 16px;
  font-family: Microsoft YaHei,serif;
  font-weight: bold;
  color: #142C44;
  line-height: 32px;
}
.content .el-row .text {
  font-size: 16px;
  font-family: Microsoft YaHei,serif;
  color: #142C44;
  line-height: 36px;
}
.copyright {
  font-size: 12px;
  font-family: SimSun,serif;
  font-weight: 400;
  color: #FFFFFF;
  height: 40px;
  background: #272B2E;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  text-decoration: none;
  color: #fff;
}
</style>