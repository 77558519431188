import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import VueRouter from "vue-router"

import Index from './components'
import Register from './components/registerIndex'
import Agreement from './components/agreement'
import Faq from './components/faq'
import AboutUs from './components/about'

import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueRouter)

const routes = [
  {path: '/', component: Index, meta: {title: ''}},
  {path: '/register', component: Register, meta: {title: '入驻'}},
  {path: '/agreement', component: Agreement, meta: {title: '协议'}},
  {path: '/faq', component: Faq, meta: {title: '常见问题'}},
  {path: '/about', component: AboutUs, meta: {title: '关于我们'}}
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

router.beforeEach(function (to, from, next) {
  let title = to.meta.title
  const processTitle = process.env.VUE_APP_TITLE || '票友友'
  window.document.title = `${processTitle}${ title ? ` | ${title}` : ''
  }`
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
