<template>
  <el-header class="header">
    <el-link class="el-link" :underline="false" :class="curPage === 'index' ? 'selected' : ''" href="/">首页</el-link>
    <el-link :class="curPage === 'faq' ? 'selected' : ''" :underline="false" href="faq">常见问题</el-link>
    <el-link :class="curPage === 'about' ? 'selected' : ''" :underline="false" href="about">关于我们</el-link>
    <el-link class="el-button" :underline="false" type="primary" round size="small" href="https://yadmin.yinglib.com/admin/cterp/login">商户登录</el-link>
  </el-header>
</template>

<script>
export default {
  name: "Header",
  data () {
    return {
      curPage: 'index'
    }
  },
  mounted: function () {
    this.curPage = this.$curPage
    switch (this.$route.path) {
      case '/faq':
        this.curPage = 'faq'
        break
      case '/about':
        this.curPage = 'about'
        break
      default:
        this.curPage = 'index'
        break
    }
    console.log(this.$route)
  }
}
</script>

<style scoped>
.header {
  min-width: 1298px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header .el-link {
  margin-right: 34px;

  font-size: 16px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
  color: #142C44;
}
.header .selected {
  color: #3C72E3;
}
.header .el-button {
  height: 36px;
  background: #3C72E3;
  border-radius: 18px;

  font-size: 14px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 12px;
}
</style>
