<template>
  <div class="container">
    <div class="form">
      <h3>使用协议</h3>
      <div>您在使用影栗的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容。如您不同意本服务协议应立即停止入驻。您一旦使用影栗的服务，即视为您已了解并完全同意本服务协议各项内容，包括对服务协议所做的修改，并成为我们的商户。</div>
      <ul>
        <li>一、商户入驻</li>
        <ul>
          <li>1、您应当同意本协议的条款并按照页面上的提示完成全部的入驻程序。您在进行入驻程序或第三方授权登录程序过程中勾选协议即表示您与影栗达成协议，完全接受本协议项下的全部条款约束。</li>
          <li>2、当您选择通过第三方授权登录程序进入影栗，您需授权影栗收集您的账号昵称及头像等标识化的个人信息，授权完成后，您即成为影栗的商户。<br />
            当您按照入驻页面提示填写信息并入驻成功后，您即成为影栗的商户。影栗将给予您一个商户帐号，密码由您自行设置。该商户帐号和密码由您自行负责保管。<br />
            您应当对您在影栗进行的活动和事件负法律责任。</li>
          <li>3、本协议服务条款和公告可由影栗定时更新，并按照法定流程进行公示。您在使用相关服务时,应关注并遵守其所适用的相关条款。</li>
          <li>4、您确认，在您开始使用本服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您需要在您监护人的陪同下完成入驻，并且您与您的监护人应依照法律规定承担因此而导致的一切后果。</li>
        </ul>
        <li>二、入驻信息和隐私保护</li>
        <ul>
          <li>1、您帐号的所有权归影栗，使用权归您。您按入驻页面引导填写信息，阅读并同意本协议且完成全部入驻程序后，即可获得入驻账号并成为商户。您应提供及时、详尽及准确的个人资料，并不断更新入驻资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为入驻资料。如果因入驻信息不真实或更新不及时而引发的相关问题，由您自行承担相应的责任。</li>
          <li>2、您应当通过真实身份信息认证入驻帐号，且您提交的商户名称、账号等入驻信息中不得出现违法和不良信息，经公司审核，如存在上述情况，影栗将不予入驻；同时，在入驻后，如发现您以虚假信息骗取帐号名称入驻，或其帐号入驻信息存在违法和不良信息的，影栗有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。</li>
          <li>3、您帐号包括帐户名称和密码，账户名称作为您有效的身份凭证之一。</li>
          <li>4、您不应将其帐号、密码转让、出售或出借予他人使用，若您授权他人使用帐户，应对被授权人在该帐户下发生所有行为负全部责任。由于您其他账户使用信息，仅当依法律法规、司法裁定或经影栗同意，并符合影栗规定的商户帐号转让流程的情况下，方可进行帐号的转让。</li>
          <li>5、因您个人原因导致的帐号信息遗失，如需找回帐号信息，可联系影栗进行找回。</li>
          <li>6、在需要终止使用帐号服务时，符合以下条件的，您可以向影栗申请注销您的帐号。</li>
          <li>7、当您使用影栗产品进行支付、登录等操作的时候，服务器会自动接收、验证、并记录一些必要的信息，如手机号码、IP地址、服务访问异常信息、以及部分设备信息等，以保障您在使用服务时账户登录和支付过程的安全，进而保护您的上网安全。您使用影栗的服务，即表示您同意影栗可以按照《隐私政策》处理您的个人信息。影栗可能会与合作伙伴共同向您提供您所要求的服务或者共同向您展示您可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，您同意影栗可与其分享必要的信息。并且，影栗会要求其确保数据安全并且禁止用于任何其他用途。除此之外，影栗不会向任何无关第三方提供或分享信息。</li>
          <li>8、您知悉并授权，影栗仅在必需的情况下使用或与关联公司同步您的信息，以为您提供更好的服务。</li>
          <li>9、为更好地向您提供服务，您同意影栗通过短信、app通知等形式向您发送相关商业性服务信息。</li>
        </ul>
        <li>三、使用规则</li>
        <ul>
          <li>1、您在使用影栗的服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:</li>
            <ul>
              <li>（1）您设置的商户名称或提供的信息不得含有下列内容之一的信息：</li>
              <ul>
                <li>① 反对宪法所确定的基本原则的；</li>
                <li>② 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
                <li>③ 损害国家荣誉和利益的；</li>
                <li>④ 煽动民族仇恨、民族歧视、破坏民族团结的；</li>
                <li>⑤ 破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                <li>⑥ 散布谣言，扰乱社会秩序，破坏社会稳定的；</li>
                <li>⑦ 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
                <li>⑧ 侮辱或者诽谤他人，侵害他人合法权利的；</li>
                <li>⑨ 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</li>
                <li>⑩ 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</li>
              </ul>
              <li>（2）不得为任何非法目的而使用影栗服务系统；</li>
              <li>（3）不利用影栗的服务从事以下活动：</li>
              <ul>
                <li>① 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</li>
                <li>② 未经允许，对计算机信息网络功能进行删除、修改或者增加的；</li>
                <li>③ 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</li>
                <li>④ 故意制作、传播计算机病毒等破坏性程序的；</li>
                <li>⑤ 其他危害计算机信息网络安全的行为。</li>
              </ul>
              <li>2、您违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿影栗与合作公司、关联公司，并使之免受损害。对此，影栗有权视您的行为性质，采取包括但不限于删除您发布信息内容、暂停使用许可、终止服务、限制使用、回收帐号、追究法律责任等措施。对恶意入驻帐号或利用帐号进行违法活动、捣乱、骚扰、欺骗、其他商户以及其他违反本协议的行为，影栗有权回收其帐号。同时，影栗会视司法部门的要求，协助调查。</li>
              <li>3、您需要对自己在服务使用过程中的行为负法律责任，即使您已经终止使用该服务。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在影栗首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予影栗等额的赔偿。</li>
              <li>4、影栗，有权根据认定程序及标准判断您账户的使用可能危及您的账户安全或涉及影栗平台信息安全的，拒绝提供相应服务或终止本协议。</li>
            </ul>
        </ul>
        <li>四、服务内容</li>
          <ul>
            <li>1、您在交易过程中与其他商户发生争议的，您或其他商户中任何一方可以自行协商解决，也可以通过影栗协助处理，若您觉得以上的方式均无法达到您要的结果，您可以寻求司法机关的协助。</li>
            <li>2、您选择影栗介入的方式处理相关问题，那么您需要遵守和执行影栗的处理结果。若您对影栗的处理结果不满意，您可以寻求其他途径解决纠纷例如起诉、仲裁等方式，但在此之前您需先履行该结果。</li>
            <li>3、影栗网络服务的具体内容由影栗根据实际情况提供。</li>
            <li>4、鉴于网络服务的特殊性，您同意影栗有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务），影栗不担保网络服务不会中断。</li>
            <li>5、终止服务<br />
              您同意依照本服务协议任何规定暂停使用服务，无需进行事先通知即可中断或终止。您承认并同意，影栗可立即关闭或删除您的帐号及所有相关信息及文件，同时影栗对您或任何第三人均不承担任何责任。</li>
          </ul>
        <li>五、个人数据保护与授权</li>
          <ul>
            <li>1、为了保护您的财产及信息安全，预防诈骗或者其他网络犯罪行为，影栗有权收集您的个人信息，包括但不限于个人信息、交易信息来判断您个人的交易风险，对您身份进行验证等。</li>
            <li>2、根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</li>
              <ul>
                <li>①、涉及公共安全，与重大利益相关的；</li>
                <li>②、与政府部门包括司法机关等相关的；</li>
                <li>③、出于保护您的个人财产或者利益的目的，但无法事先征求您同意的行为；</li>
                <li>④、您以自行对外公开的，包括但不限于通过报纸、新闻等方式公开；</li>
                <li>⑤、根据您的要求签订合同所必需的；</li>
                <li>⑥、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</li>
                <li>⑦、国家法律法规政策认可或支持的相关行为；</li>
              </ul>
            <li>3、我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</li>
          </ul>
        <li>六、免责声明<br />
          因以下情况造成网络服务在合理时间内的中断，影栗无需为此承担任何责任；</li>
          <li>（1）影栗需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，影栗保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</li>
          <li>（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</li>
          <li>（3）您的电脑软硬件和通信线路、供电线路出现故障的；</li>
          <li>（4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。</li>
          <li>尽管有前款约定，影栗将采取合理行动积极促使服务恢复正常。</li>
        <li>七、知识产权和其他合法权益</li>
          <ul>
            <li>1、影栗产品及相关网站上由影栗上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由影栗或其关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。但如因您上传的由其自行制作、拥有的内容涉及侵犯影栗或其他任何第三方的合法权益的，您应自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给影栗造成的损失（包括但不限于影栗声誉的影响、影栗由此承担的连带责任（如有）等）进行赔偿。</li>
            <li>2、非经影栗或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在本网站上程序或内容（仅限于由影栗上传、制作、拥有的所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计等）；如您需使用著作权非影栗所有的内容的，您应获得具体内容的著作权所有者的合法授权才能使用，如因您私自使用非自己所有的、且未经他人合法授权的著作、图片、档案、资讯、资料等内容的，由您自行承担责任，包括但不限于您自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给乙方造成的损失（包括但不限于乙方声誉的影响、乙方由此承担的连带责任（如有）等）进行赔偿。</li>
            <li>3、尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</li>
          </ul>
        <li>八、其他</li>
          <ul>
            <li>1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。</li>
            <li>2、 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。</li><li>3、本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。</li>
            <li>4、影栗未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</li>
            <li>5、本协议最终解释权及修订权在法律范围内归影栗所有。</li>
          </ul>
      </ul>
      <br />
      <div class="close"><el-button v-on:click="$emit('close')">关闭</el-button></div>
    </div>
    <div class="copyright">Copyright © 2018-2023 福建省福栗网络科技有限责任公司版权所有 All Rights Reserved.   <a class="btn" target="_blank" href="https://beian.miit.gov.cn/">闽ICP备2021003651号-1</a></div>
  </div>
</template>

<script>
export default {
  name: "agreement"
}
</script>
<style>
body {
  background-color: #EFF7FF;
}
</style>
<style scoped>
.container {
  background-image: url("~@/static/register-background.png");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 86px;
}
.form {
  width: 1200px;
  padding: 50px 50px;
  min-height: 756px;
  background: #FFFFFF;
  box-shadow: 0px 7px 27px 0px rgba(133, 169, 231, 0.51);
  border-radius: 20px;
  margin: 0 auto;
  text-align: left;
}
.form li{
  list-style: none;
}
.form h3 {
  text-align: center;
}
.close {
  text-align: center;
}
.copyright {
  margin-top: 121px;
  font-size: 12px;
  font-family: SimSun;
  font-weight: 400;
  color: #142C44;
  margin-bottom: 27px;
}
.btn {
  text-decoration: none;
  color: #142C44;
}
</style>