<template>
  <div class="container">
    <el-form class="form" :model="formData" :rules="rules" ref="formData">
      <div class="title">商户入驻</div>
      <el-form-item class="el-form-item" prop="name">
        <el-input
            placeholder="请输入商户名称"
            class="inputbox"
            maxlength="50"
            v-model="formData.name"
            show-word-limit
            >
          <img
              alt="商户名称"
              slot="prefix"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAA1VBMVEUAAAC/v7/MzMy2ttu/v7+7u8y2ts68vNO1tcq6us65wdO7u9S3v8+5vtK3vM+5vtG2u9G7v9G5vdO5vdK5vNC8v9O5vM+5v9G6vc+6wNK8v9O5v9O6vNC6v9K6vdC5vtG6vNK6v9K6v9G4vdG5vtK6v9O6vtK7v9O4vNK6vtK6v9K5vtK6vtK7v9K6vtG7vtO6vtG5vdK6v9K6vtG5vtK6vdG6vtK6vtK6vtK6vtK6vdG6vtK6vtK6vtG6vtK6vdK5vdK7v9K6vtG6vtK6vtO6vtK6vtLWpp5MAAAARnRSTlMABAUHCA8VFxgaHR4gMzU3ODg6PkxMUFRVVVdjZ2doamtrb3Bxc31/goKjpaavuLjP0dLT19ne3+Hi5ejv8PP0+fr7/P3+rF/6RQAAAJlJREFUGBmNwelWQWEAQNFDs66KikSXBppLM0ppuOf9H4m7Mn1r9aO9+dP67fPLRLtC6tI5H0vA6sB5DaBl4A4yPe1cj92rW2yodcaK6imbatJ/+/WtnpPtGvjJQ+FV+8dP+t580K8jRnb2d7cvDsp7pZuzw5PGMpAz+XSmBkQGYiAyEAORgRhYM1Bl5Cpx5nGFVHZxaoH/GgIK1TmOQIb++wAAAABJRU5ErkJggg=="
              class="el-input__icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="account">
        <el-input
            placeholder="请输入管理员账号（支持中英文、数字，限20个字符以内）"
            class="inputbox"
            maxlength="20"
            v-model="formData.account"
            show-word-limit
        >
          <img
              alt="账号"
              slot="prefix"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAABwklEQVQ4T63TzUvbcBgH8O8TY6mDgaAD2WEXZS+yxCiUHuaUJjFxOOhh7LCr4D8gyC5edxnC7rt48yRqQQppmxSxDDY2JW29TPCwy9jYYWyHDa15pJ2WvsQkheUW8n0+/J7f84Twnx4KczKZ0k0MDSM9ff93UPZayLJdlYheA5gCQMw4YMbLeV0u+IG+kGUfqUTnFgCxo6gGpgVDk3KdmC+Uc9xDgJRrWqkaqiyFQoXC+yFPGPgRdB9/PBpJ69K31kzXibLZg1tiXPweBHmieHt+ZvxrIFT/mHcqRwwe98WYjg1NuhvaWj1gFStPiZEBWGgvIE8gfqan5J1I0L9TldMMWgN47LLohOCtzKnKVuTxtwbz+eodxIG5xw+/RF7I/F7lAdf4FQgJ6t6hhsNAjYFPOONV05yoXuHNqWXtymg/8UcGBsN+mwbI+NXHXkLXlc/19yZk2e5bIlqKgrRk1g1VXmyDco5bAuhRbxB9MFQp2QGVNwC86AVi5k1Tm3je3prjJgm0D6A/IlZjpllTk961QY1FtMtPQHhDwL1AjPmYBV42U8pu19RaC7eLh4MxxOJ+2A2c/k2lJn9G3uyI7TVjF+bcixWWRA+CAAAAAElFTkSuQmCC"
              class="el-input__icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            placeholder="请输入密码（6~32位数字或字符）"
            class="inputbox"
            v-model="formData.password"
            maxlength="32"
            show-password
        >
          <img
              alt="密码"
              slot="prefix"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAABjElEQVQ4T7XUP0gCcRQH8O/zHxWFgbQ01FqDV4ubQ3fiKbS0tTo25BBuDe1C0RJUS7Tm0BaYpiZIuDR4GrQXRFEQiUSp9+IsxT93pma/+b3Pe/d7v3eEIR0akgNDKJ7Oz6OKdWZ2fRfja5V4zy8t3ugV14ViKSUAxiEBdwxEwWAQ/ABmGbTmk5xH7VgHdHFZcKmqegXGgdX0siGKYkVLikTYbHfkt4k4SAS3V1zINmMdUCylnIIx4xWdLiLi5mBmpniqkGXwg08SVrpDydwTiHZkUQjr3UUslQ+xyps+j+D4BVKKIARlUTjWhRJKAET7suQcNYQymduJ0sfnPTFvlc2WEz3IWq2swkThMZttyu2eK9ZjWu4ollSeAbS0bPjOGG+yR7AbQPl3gEd6faSyJDQaaeuoAyozqyEzWaoq1F0AtuYi/UBpWRKWtOTzRC5BRNKgUAnAMjOYCGcAxgeFtGWM1jYNtRVpOf18mpb4+pM9+VfIcIhdOlISAKZ7HP9jfRBa/P//2HrsqhH2BQ5/ohV4yXtgAAAAAElFTkSuQmCC"
              class="el-input__icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="password2">
        <el-input
            placeholder="请确认密码（6~32位数字或字符）"
            class="inputbox"
            v-model="formData.password2"
            maxlength="32"
            show-password
        >
          <img
              alt="密码"
              slot="prefix"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAABjElEQVQ4T7XUP0gCcRQH8O/zHxWFgbQ01FqDV4ubQ3fiKbS0tTo25BBuDe1C0RJUS7Tm0BaYpiZIuDR4GrQXRFEQiUSp9+IsxT93pma/+b3Pe/d7v3eEIR0akgNDKJ7Oz6OKdWZ2fRfja5V4zy8t3ugV14ViKSUAxiEBdwxEwWAQ/ABmGbTmk5xH7VgHdHFZcKmqegXGgdX0siGKYkVLikTYbHfkt4k4SAS3V1zINmMdUCylnIIx4xWdLiLi5mBmpniqkGXwg08SVrpDydwTiHZkUQjr3UUslQ+xyps+j+D4BVKKIARlUTjWhRJKAET7suQcNYQymduJ0sfnPTFvlc2WEz3IWq2swkThMZttyu2eK9ZjWu4ollSeAbS0bPjOGG+yR7AbQPl3gEd6faSyJDQaaeuoAyozqyEzWaoq1F0AtuYi/UBpWRKWtOTzRC5BRNKgUAnAMjOYCGcAxgeFtGWM1jYNtRVpOf18mpb4+pM9+VfIcIhdOlISAKZ7HP9jfRBa/P//2HrsqhH2BQ5/ohV4yXtgAAAAAElFTkSuQmCC"
              class="el-input__icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input
            placeholder="请输入手机号"
            class="inputbox"
            v-model.number="formData.mobile"
            maxlength="11"
            show-word-limit
        >
          <img
              alt="手机号"
              slot="prefix"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAA/klEQVQ4T+3UPUoDURTF8f95E6MIWthoYZGksjGSJTgygSzCjy24EpcgxEUEdBJ3oDiVlcYujRYWDpLkXUkhxHEyTi155eHxe3B594icE8dJY+LchtNY3jsXeKrz1yT3MQ4m753D1vA7V9a57ieXiLO8B7KZUDcK909n+W9okDwDI03tvAizQBfATjts1hdCgscobHaKoJtB0jPYW0KLp7Sc0b/9R6At4OmPxW2AvRWuCFD7URtwZ2ZCamXwYWnIYFTVaz1Nd62ylr4A23NYeQj49N6iIDAz72LEajmo/9BFOi5TbJhdtY8OTnL7aBb2bu9rK9PKpplfzwPzqvYLITbHFY/dNRgAAAAASUVORK5CYII="
              class="el-input__icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <div class="verify-form-item">
          <el-input
              placeholder="请输入验证码"
              class="inputbox verify-input"
              v-model.number="formData.code"
              maxlength="4"
          >
            <img
                alt="验证码"
                slot="prefix"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAACLklEQVQ4T63UPWhTURQH8P+5SWr8arVmcFAQpIKYj1KCg4XCe/KSqDSbClLwA7eADk5+DDoEJxWqa0Q6CAYdFAeNNU+Xgi5tklakBJRCQRCFDoaaj/uXRBPTJm1a8W2Pc+7vnfPuuVfwnx7p5CSTdGzZOdl7dGjg62q5q0Kptx/6oMuPQAmQHC0WileHh4OFdmALRFJe2VOHQHUGokYAupsWzkPkjoZ6GjEO5JvBBjRu505WyPMC6Qfo6dQygS8CvHeJ66Jh7P/cgFJ2bhZkXydgeZzglbAZuPkXSmc/AdizXkhErluG78b6IXJWidzSwD0Arn+CSGThpqWK3EeKDYhTRK5Zhi/eviJKGMIYgGi9VYLvSgU54tikDzqgngDY/Cd2KWT6bzegl+lsXoC91SChYpXFuYTTvfsZwBBJu0s5o2XqEMGHADbUP6DB0xEzMNZc0XMAx34niKbocyjiMZy4vPDtR3y7p/s4oRMAnM0boqG9EbN/pgG9eD11Vom632iFrChRpyzTl0zZ2RgoowBVM0IgHzb9tZFpQMnkTNe2HXoasmSWShC8AWG1GwtSLoQP++4ugaovqfTkIOCwq9u6hnma7u0uDgSDwVILVMPGcyNQfADAsSJGfKfCYNjwf6zntD39qXQmCmIMIj0tGDGnRUUjpjfT9tAuX2DbmV1lqjiBE7UboFqFSEJVfsYtK7iwPL/zxTYxsbGntNUTGvLOi4heqd2O0Bp+ei3lF8tT2BXzLU/1AAAAAElFTkSuQmCC"
                class="el-input__icon"
            />
          </el-input>
          <el-button
              type="primary"
              round
              plain
              size="small"
              :disabled="codeButtonDisabled"
              class="verify-button"
              v-on:click="getCode"
              :loading="codeButtonLoading"
          >{{ codeButtonText}}</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round class="register-button" v-on:click="submitForm('formData')" :loading="regButtonLoading" :disabled="regButtonDisabled">免费入驻</el-button>
      </el-form-item>
      <div class="tips">点击【免费入驻】即视为同意影栗<el-link v-on:click="$emit('open')">《使用协议 》</el-link>《禁售协议》</div>
    </el-form>
    <div class="copyright">Copyright © 2018-2023 福建省福栗网络科技有限责任公司版权所有 All Rights Reserved.   <a class="btn" target="_blank" href="https://beian.miit.gov.cn/">闽ICP备2021003651号-1</a></div>
  </div>
</template>

<script>
// import qs from 'qs'

export default {
  name: "register",
  data: function () {
    let validateEmtpy = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'));
      } else if (value.length < 3) {
        callback(new Error('输入内容太短'));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 6) {
        callback(new Error('密码长度不能小于6位'));
      } else {
        if (this.formData.password !== '') {
          this.$refs.formData.validateField('password2');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    let validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!(/^1[3456789]\d{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号码!'));
      } else {
        callback();
      }
    };
    let validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else if (!Number.isInteger(value) || value.toString().length !== 4 ) {
        callback(new Error('请输入有效验证码'));
      } else {
        callback();
      }
    };
    return {
      codeButtonText: '获取验证码',
      codeButtonDisabled: true,
      codeButtonLoading: false,
      regButtonDisabled: false,
      regButtonLoading: false,
      formData : {
        name: '',
        account: '',
        password: '',
        password2: '',
        mobile: '',
        code: ''
      },
      rules: {
        name: [
          {validator: validateEmtpy, trigger: 'blur'}
        ],
        account: [
          {validator: validateEmtpy, trigger: 'blur'}
        ],
        password: [
          {validator: validatePass, trigger: 'blur'}
        ],
        password2: [
          {validator: validatePass2, trigger: 'blur'}
        ],
        mobile: [
          {validator: validateMobile, trigger: 'blur'}
        ],
        code: [
          {validator: validateCode, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    getData: function () {
      this.regButtonLoading = true
      this.regButtonDisabled = true
      this.$axios.post('/account/client/createChannelClientAndAdmin', {
        clientName: this.formData.name,
        username: this.formData.account,
        password: this.formData.password,
        mobile: this.formData.mobile,
        code: this.formData.code
      }).then(res => {
        this.regButtonLoading = false
        this.regButtonDisabled = false
        if (!res.data.ret) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
        else {
          this.$emit('success')
        }
      })
      .catch(error => {
        this.regButtonDisabled = false
        this.regButtonLoading = false
        this.$message({
          showClose: true,
          message: '网络通讯异常',
          type: 'error'
        })
        console.log(error)
      })
    },
    submitForm: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getData()
        } else {
          return false
        }
      })
    },
    getCode: function () {
      this.$refs['formData'].validateField('mobile', (valid) => {
        if (!valid) {
          let lTime = Number(localStorage.getItem('sendCode'))
          let nTime = Number(new Date()/1000)
          if (lTime < nTime) {
            let time = Number(new Date()/1000)+60
            localStorage.setItem('sendCode', time.toString())
            this.codeButtonDisabled = true
            this.codeButtonText = '60秒后重新发送'

            this.codeButtonLoading = true
            this.$axios.post('/account/smsLog/sendCode', {
              mobile: this.formData.mobile
            }).then(res => {
              this.$message({
                showClose: true,
                message: '验证码已发送',
                type: 'success'
              })
              this.codeButtonLoading = false
              console.log(res);
            }).catch(error => {
              this.codeButtonLoading = false
              console.log(error)
            })
          }
          else {
            this.codeButtonDisabled = true
            this.codeButtonText = Math.ceil(lTime - nTime) + '秒后重新发送'
          }
          this.intervalTime()
        }
      });
    },
    intervalTime: function () {
      this.timer = setInterval(() => {
        let lTime = Number(localStorage.getItem('sendCode'))
        let nTime = Number(new Date()/1000)

        if (lTime - nTime <= 0) {
          this.codeButtonDisabled = false
          this.codeButtonText = '获取验证码'
          clearInterval(this.timer)
        }
        else {
          this.codeButtonDisabled = true
          this.codeButtonText = Math.ceil(lTime - nTime) + '秒后重新发送'
        }
      }, 1000)
    }
  },
  mounted: function () {
    this.intervalTime()
  }
}
</script>
<style>
body {
  background-color: #EFF7FF;
}
.el-form-item {
  width: 440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 26px;
}
.inputbox .el-input__inner {
  width: 440px;
  height: 46px;
  background: #FFFFFF;
  border: 1px solid #E4E8F0;
  border-radius: 23px;
  padding-left: 52px;
  font-size: 14px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
}
.verify-input .el-input__inner {
  width: 290px;
}
.inputbox .el-input__prefix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 22px;
}
</style>
<style scoped>
.container {
  background-image: url("~@/static/register-background.png");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 86px;
}
.form {
  width: 1300px;
  min-height: 756px;
  background: #FFFFFF;
  box-shadow: 0 7px 27px 0 rgba(133, 169, 231, 0.51);
  border-radius: 20px;
  margin: 0 auto;
}
.title {
  font-size: 36px;
  font-family: Microsoft YaHei,serif;
  font-weight: bold;
  color: #142C44;
  padding-top: 80px;
  padding-bottom: 34px;
}
.inputbox {
  width: 440px;
}
.verify-input {
  width: 290px;
}
.verify-button {
  flex-grow: 1;
  margin-left: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
}
.el-input__icon {
  height: 20px;
  width: 18px;
}
.verify-form-item {
  display: flex;
  margin: 0 auto;
  width: 440px;
}
.register-button {
  width: 440px;

  font-size: 16px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
  color: #FFFFFF;
  height: 56px;
  background: #3381FF;
  box-shadow: 0 7px 27px 0 rgba(50, 129, 255, 0.51);
  border-radius: 28px;
}
.tips {
  font-size: 12px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
  color: #818C98;
  padding-bottom: 86px;
}
.copyright {
  margin-top: 121px;
  font-size: 12px;
  font-family: SimSun,serif;
  font-weight: 400;
  color: #142C44;
  margin-bottom: 27px;
}
.btn {
  text-decoration: none;
  color: #142C44;
}
</style>