<template>
  <div>
    <Agreement v-show="view === 'agreement'" v-on:close="onClick('register')"></Agreement>
    <Success v-show="view === 'success'" v-on:close="onOpen('register')"></Success>
    <Register v-show="view === 'register'" v-on:open="onClick('agreement')" v-on:success="onClick('success')"></Register>
  </div>
</template>

<script>
import Register from "@/components/register"
import Agreement from "@/components/agreement"
import Success from "@/components/success"

export default {
  name: "registerIndex",
  data : function () {
    return {
      view: 'register'
    }
  },
  components: {
    Register,
    Agreement,
    Success
  },
  methods: {
    onClick: function (opt) {
      this.view = opt
    },
    onOpen: function () {
      window.location.href = 'http://yadmin.yinglib.com/admin/cterp/login';
    }
  }
}
</script>

<style scoped>

</style>