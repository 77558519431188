<template>
  <div class="container">
    <div class="card">
      <div class="form">
        <h3>关于我们</h3>
        <div class="eng">About Us</div>
        <div>影栗、新电影、心服务，平台致力于为商户提供智能化的虚拟商品库存管理，虚拟商品发货、电影票接单服务，极大提高了传统“人工机械”经营所创造的效益。 </div>
        <div>联系地址：福建省福州市鼓楼区黄巷</div>
        <div>联系邮箱：admin@yinglib.com</div>
        <!--<div>客服电话：400-0000-0000</div>-->
      </div>
    </div>
    <div class="copyright">Copyright © 2018-2023 福建省福栗网络科技有限责任公司版权所有 All Rights Reserved.   <a class="btn" target="_blank" href="https://beian.miit.gov.cn/">闽ICP备2021003651号-1</a></div>
  </div>
</template>

<script>
export default {
  name: "about"
}
</script>
<style>
body {
  background-color: #EFF7FF;
}
</style>
<style scoped>
.container {
  background-image: url("~@/static/about.png");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 86px;
}
.card {
  width: 1920px;
  margin: 0 auto;
}
.form {
  width: 420px;
  background: #FFFFFF;
  box-shadow: 0 7px 27px 0 rgba(133, 169, 231, 0.51);
  border-radius: 20px;
  text-align: left;
  margin-left: 340px;
  padding: 87px 45px 100px 45px;
}
.form h3 {
  margin-block: 0;
  padding: 0;
  font-size: 44px;
  font-family: Microsoft YaHei,serif;
  font-weight: bold;
  color: #3B3D49;
}
.form div {
  font-size: 20px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
  color: #142C44;
  line-height: 48px;
  margin-bottom: 20px;
}
.form .eng {
  font-size: 22px;
  font-family: Microsoft YaHei,serif;
  font-weight: 400;
  color: #BABED2;
}
.copyright {
  font-size: 12px;
  font-family: SimSun,serif;
  font-weight: 400;
  color: #FFFFFF;
  height: 40px;
  background: #272B2E;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  text-decoration: none;
  color: #fff;
}
</style>